<template>
  <v-card flat class="h100">
    <v-card-text class="h100">
      <v-btn
        color="primary"
        dark
        class="secondary--text"
        @click="generatebatch"
        block
      >
        <v-icon class="mr-4">mdi-image-move</v-icon>
        Generate new <br />
        image batch
      </v-btn>

      <TaskSelect />
      <v-card>
        <v-list dense>
          <v-subheader>Files To Annotate</v-subheader>
          <v-list-item-group v-model="selectedItem" color="primary">
            <v-list-item
              v-for="(file, i) in files"
              :key="i"
              :disabled="file.annotated == 2"
            >
              <v-list-item-icon>
                <v-icon v-text="getIcon(file.annotated)"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-tooltip top>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-list-item-title
                      v-text="
                        '...' +
                          file.png
                            .split('/')
                            .slice(-2)
                            .join('/')
                      "
                      class="font-weight-light text-left"
                      v-on="{ ...tooltip }"
                    ></v-list-item-title>
                  </template>
                  <span>{{ file.png }}</span>
                </v-tooltip>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import TaskSelect from "@/components/SidePanels/Left/TaskSelect";
import {
  getFilesList,
  importImageWithMask,
  generatepng
} from "@/common/api.gmm.js";
import { disableViewport } from "larvitar";
import { findIndex } from "lodash";

export default {
  name: "LeftSidePanelGmm",
  data() {
    return {};
  },
  components: {
    TaskSelect
  },
  computed: {
    ...mapState({
      selectedTask(state) {
        return state.gmm.selectedTask;
      },
      files(state) {
        return state.gmm.files;
      }
    }),
    selectedItem: {
      get() {
        return this.$store.state.gmm.selectedItem;
      },
      set(value) {
        this.$store.commit("gmm/setSelectedItem", value);
        return value;
      }
    }
  },
  watch: {
    selectedTask: function(task) {
      // reset file list
      this.$store.commit("gmm/setSelectedItem", null);
      this.$store.commit("gmm/setFilesList", []);
      disableViewport("viewer");

      let self = this;
      getFilesList(task, function(list) {
        if (list && list.length > 0) {
          self.$store.commit("gmm/setFilesList", list);
          self.$store.commit("gmm/setSelectedItem", 0);
        }
      });
    },
    selectedItem: function(item) {
      let nextFile = this.files[item];
      if (nextFile && nextFile.annotated == 0) {
        this.renderImage(this.files[item]);
      } else {
        // pick first file that is not annotated
        let index = findIndex(this.files, ["annotated", 0]);
        if (index >= 0) {
          this.$store.commit("gmm/setSelectedItem", index);
        } else {
          disableViewport("viewer");
          this.$store.commit("gmm/setSelectedItem", null);
        }
      }
    }
  },
  methods: {
    renderImage(image) {
      let self = this;
      self.$store.dispatch("gmm/setIsLoading", true);
      importImageWithMask(image, function() {
        self.$store.dispatch("gmm/setIsLoading", false);
      });
    },
    getIcon(annotation) {
      let icon = "";
      switch (annotation) {
        case 0:
          icon = "mdi-clipboard-outline";
          break;
        case 1:
          icon = "mdi-clipboard-text-outline";
          break;
        case 2:
          icon = "mdi-clipboard-check-outline";
          break;
        default:
          icon = "mdi-clipboard-outline";
      }
      return icon;
    },
    generatebatch: function() {
      let self = this;
      let userConfirmation = confirm(
        "Are you sure? This will generate all the pngs for the new batches"
      );
      if (userConfirmation) {
        self.$store.dispatch("gmm/setIsLoading", true);
        generatepng(function() {
          self.$store.dispatch("gmm/setIsLoading", false);
        });
      }
    }
  }
};
</script>

<style scoped>
.v-navigation-drawer {
  overflow: hidden !important;
}
.w100 {
  width: 100%;
}
.h100 {
  height: 100%;
}
.h90 {
  height: 90%;
}
.h10 {
  height: 10%;
}
.center-text {
  text-align: center;
}
.scrollable {
  overflow-y: auto;
}
</style>
