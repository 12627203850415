// external libraries

function initialState() {
  return {
    activeImage: null,
    activeMask: null,
    metadata: {}
  };
}

const state = {
  ...initialState()
};

const mutations = {
  reset(state) {
    // acquire initial state
    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  setActiveImage(state, value) {
    state.activeImage = value;
  },
  setActiveMask(state, value) {
    state.activeMask = value;
  },
  setMetadata(state, value) {
    state.metadata = value;
  }
};

const actions = {
  setActiveImage({ commit }, value) {
    commit("setActiveImage", value);
  },
  setActiveMask({ commit }, value) {
    commit("setActiveMask", value);
  },
  setMetadata({ commit }, value) {
    commit("setMetadata", value);
  }
};

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
