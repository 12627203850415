// external libraries
import uuid from "uuid";
import { each, filter, isEmpty, find, flatten } from "lodash";
var path = require("path");

// internal libraries
import store from "@/store/index";
import { supportedFiles } from "@/../package.json";

// global config variables
const ALLOWED_EXTENSIONS = supportedFiles;

// =============================================
// Build a tree data structure from FileList ===
// =============================================
export function buildData(files) {
  let idmap = {};
  let data = [];

  for (var i = 0; i < files.length; ++i) {
    let aFile = files[i];
    let dirs = aFile.webkitRelativePath.split("/");
    add(aFile, dirs, idmap, data);
  }

  return data;
}

// ==================================================
// Internal recursive function to build data tree ===
// ==================================================
function add(aFile, dirs, idmap, data) {
  if (!dirs.length) return "#";
  let name = dirs.join("/");
  if (name in idmap) return idmap[name];
  let dir = dirs.pop();
  let parent = add(aFile, dirs, idmap, data);
  let id = uuid.v4();

  let fileExtension = path.extname(dir);

  if (fileExtension) {
    if (ALLOWED_EXTENSIONS.includes(fileExtension)) {
      data.push({
        id: id,
        parent: parent,
        name: dir,
        file: aFile,
        size: fileExtension ? aFile.size : null,
        type: fileExtension ? aFile.type : null,
        leaf: fileExtension ? true : false
      });
    }
  } else {
    data.push({
      id: id,
      parent: parent,
      name: dir,
      file: null,
      size: null,
      type: null,
      leaf: false
    });
  }
  return (idmap[name] = id);
}

// =======================
// Return root element ===
// =======================
export function getRoot(data, rootId) {
  let id = rootId ? rootId : "#";
  let root = filter(data, function(el) {
    return el.parent == id;
  });
  return root[0];
}

// ===================================
// Return children from a parentId ===
// ===================================
export function getChildren(data, parentId) {
  let children = filter(data, function(el) {
    return el.parent == parentId;
  });
  return children;
}

// ===================================
// Return tree view for UI element ===
// ===================================
export function buildTreeView(data) {
  let root = getRoot(data);
  root.children = unflatten(data, root, []);

  return root;
}

// ===================================
// Unflatten a nested object =========
// ===================================
function unflatten(array, parent, tree) {
  let children = getChildren(array, parent.id);

  if (!isEmpty(children)) {
    if (parent.parent == "#") {
      // if root
      tree = children;
    } else {
      parent["children"] = children;
    }
    each(children, function(child) {
      unflatten(array, child);
    });
  } else {
    // do nothing
  }
  return tree;
}

// ===========================
// Return node from nodeId ===
// ===========================
export function getNode(nodeId) {
  let data = flatten(store.state.treeview.data);
  return find(data, function(el) {
    return el.id == nodeId;
  });
}
