/**
 * Override default tools configuration with gmm customizations
 */

export const tools_config = [
  // segmentation
  {
    name: "Brush",
    shortcut: "alt-q"
  },
  {
    name: "RectangleScissors",
    shortcut: "alt-w"
  },
  {
    name: "FreehandScissors",
    shortcut: "alt-e"
  },
  {
    name: "CircleScissors",
    shortcut: "alt-r"
  },
  {
    name: "CorrectionScissors",
    shortcut: "alt-t"
  },
  {
    name: "PolylineScissors",
    shortcut: "alt-y"
  },
  // utils
  {
    name: "Wwwc",
    shortcut: "alt-a"
  },
  {
    name: "WwwcRegion",
    shortcut: "alt-s"
  },
  {
    name: "Pan",
    shortcut: "alt-d"
  },
  {
    name: "Zoom",
    shortcut: "alt-f"
  },
  {
    name: "Magnify",
    shortcut: "alt-g"
  },
  {
    name: "DragProbe",
    shortcut: "alt-h"
  },
  {
    name: "ThresholdsBrush",
    type: "none" // hack to hide this tool
  }
];

/**
 * Override shortcuts and mouse bindings defaults with gmm customizations
 */
export const mouse_key_config = {
  debug: true, // log changes
  mouse_button_left: {
    // shift: "Zoom",
    // ctrl: "Pan"
    default: "Wwwc"
  },
  mouse_button_right: {
    shift: "Wwwc",
    ctrl: "Pan",
    default: "Zoom"
  },
  keyboard_shortcuts: {
    // alt key + letter
    KEY_Q: "Brush",
    KEY_W: "RectangleScissors",
    KEY_E: "FreehandScissors",
    KEY_R: "CircleScissors",
    KEY_T: "CorrectionScissors",
    KEY_Y: "PolylineScissors",
    KEY_A: "Wwwc",
    KEY_S: "WwwcRegion",
    KEY_D: "Pan",
    KEY_F: "Zoom",
    KEY_G: "Magnify",
    KEY_H: "DragProbe"
  }
};
