import Vue from "vue";
import Vuex from "vuex";

// modules
import annotations from "./modules/annotations";
import metadata from "./modules/metadata";
import tools from "./modules/tools";
import treeview from "./modules/treeview";
import gmm from "./modules/gmm";

Vue.use(Vuex);

function initialState() {
  return {
    currentPage: "home",
    accessToken: null,
    refreshToken: null,
    fileManager: null,
    manager: "fileManager"
  };
}

export default new Vuex.Store({
  state: {
    ...initialState()
  },
  mutations: {
    reset(state) {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach(key => {
        if (key != "accessToken" && key != "refreshToken") {
          state[key] = s[key];
        }
      });
    },
    setUser(state, value) {
      state.user = value;
    },
    setAccessToken(state, value) {
      state.accessToken = value;
    },
    setRefreshToken(state, value) {
      state.refreshToken = value;
    },
    setCurrentPage(state, value) {
      state.currentPage = value;
    },
    setFileManager(state, value) {
      state.fileManager = value;
    }
  },
  actions: {
    clearState({ commit }) {
      commit("reset");
      commit("annotations/reset");
      commit("metadata/reset");
      commit("tools/reset");
      commit("treeview/reset");
      commit("gmm/reset");
    },
    setUser({ commit }, value) {
      commit("setUser", value);
    },
    setAccessToken({ commit }, value) {
      commit("setAccessToken", value);
    },
    setRefreshToken({ commit }, value) {
      commit("setRefreshToken", value);
    },
    setCurrentPage({ commit }, value) {
      commit("setCurrentPage", value);
    },
    setFileManager({ commit }, value) {
      commit("setFileManager", value);
    }
  },
  getters: {},
  modules: {
    annotations: annotations,
    metadata: metadata,
    tools: tools,
    treeview: treeview,
    gmm: gmm
  }
});
