<template>
  <div>
    <section>
      <v-parallax src="/media/images/home-image-top.gif" height="600">
        <v-layout column align-center justify-center class="white--text">
          <h1 class="white--text mb-2 display-1 text-center">
            Annotate.Vision
          </h1>
          <v-btn class="mt-12" color="#BED730" dark large @click="openViewer">
            Open Annotate.Vision
          </v-btn>
        </v-layout>
      </v-parallax>
    </section>

    <section>
      <v-layout column wrap class="my-12" align-center>
        <v-flex xs12 sm4 class="my-4">
          <div class="text-center">
            <h2 class="headline">
              The fastest annotation platform for images and training AI
            </h2>
            <span class="subheading">
              A complete set of solutions for image annotation.
            </span>
          </div>
        </v-flex>
        <v-flex xs12>
          <v-container grid-list-xl>
            <v-layout row wrap align-center>
              <v-flex xs12 md4>
                <v-card flat class="transparent">
                  <v-card-text class="text-center">
                    <v-icon x-large class="color-dv text--lighten-2"
                      >mdi-auto-fix</v-icon
                    >
                  </v-card-text>
                  <v-card-title primary-title class="layout justify-center">
                    <div class="headline text-center">
                      Annotation Tools
                    </div>
                  </v-card-title>
                  <v-card-text class="text-justify">
                    Everything you need to get started with data annotation. Our
                    tools allow you to create bounding boxes, polygons, masks,
                    2D and 3D annotation datasets in the easiest way possible.
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 md4>
                <v-card flat class="transparent">
                  <v-card-text class="text-center">
                    <v-icon x-large class="color-dv text--lighten-2"
                      >mdi-brain</v-icon
                    >
                  </v-card-text>
                  <v-card-title primary-title class="layout justify-center">
                    <div class="headline">AI & ML Projects</div>
                  </v-card-title>
                  <v-card-text class="text-justify">
                    Data annotation provides the initial setup for training a
                    machine learning model. It provides a way to understand and
                    discriminate against various inputs in order to come up with
                    accurate outputs.
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 md4>
                <v-card flat class="transparent">
                  <v-card-text class="text-center">
                    <v-icon x-large class="color-dv text--lighten-2"
                      >mdi-label</v-icon
                    >
                  </v-card-text>
                  <v-card-title primary-title class="layout justify-center">
                    <div class="headline text-center">
                      Labeling Data
                    </div>
                  </v-card-title>
                  <v-card-text class="text-justify">
                    Data annotation is the task of labeling data with metadata
                    in preparation for training a machine learning model. Both
                    data and metadata come in many forms, including content
                    types such as text, audio, images.
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
    </section>

    <section>
      <v-parallax src="/media/images/home-image-bottom.gif" height="380">
        <v-layout column align-center justify-center>
          <div class="headline white--text mb-4 text-center">
            Object localization and Detection
          </div>
          <em
            >Low-friction tasks for identifying objects in digital
            photographs.</em
          >
          <v-btn class="mt-12" color="#BED730" dark large @click="openViewer">
            Open Annotate Vision
          </v-btn>
        </v-layout>
      </v-parallax>
    </section>

    <section>
      <v-container grid-list-xl>
        <v-layout row wrap justify-center class="my-12">
          <v-flex xs12 sm4>
            <v-card flat class="transparent">
              <v-card-title primary-title class="layout justify-center">
                <div class="headline">D/Vision Lab</div>
              </v-card-title>
              <v-card-text class="text-justify">
                We are an innovative start-up focused on data visualization.
                With over 15 years of experience accumulated by its founders,
                D/Vision Lab operates in scientific, medical, engineering and
                services domains allowing visual exploration of data through
                advanced visualization technologies and artificial intelligence
                to enable fast, intuitive and deep understanding of the business
                processes.
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn icon>
                  <a href="http://www.dvisionlab.com" target="blank">
                    <v-icon class="color-dv">mdi-web</v-icon>
                  </a>
                </v-btn>

                <v-btn icon>
                  <a href="mailto:info@dvisionlab.com" target="blank">
                    <v-icon class="color-dv">mdi-mail</v-icon>
                  </a>
                </v-btn>

                <v-btn icon>
                  <a href="http://www.github.com/dvisionlab" target="blank">
                    <v-icon class="color-dv">mdi-git</v-icon>
                  </a>
                </v-btn>

                <v-btn icon>
                  <a href="http://www.twitter.com/dvisionlab" target="blank">
                    <v-icon class="color-dv">mdi-twitter</v-icon>
                  </a>
                </v-btn>

                <v-btn icon>
                  <a
                    href="http://www.linkedin.com/company/dvisionlab"
                    target="blank"
                  >
                    <v-icon class="color-dv">mdi-linkedin</v-icon>
                  </a>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
          <v-flex xs12 sm4 offset-sm1>
            <v-card flat class="transparent">
              <v-card-title primary-title class="layout justify-center">
                <div class="headline">Contact Us</div>
              </v-card-title>
              <a href="http://www.dvisionlab.com" target="blank">
                <v-img
                  class="white--text align-end"
                  contain
                  src="/media/images/dvision_logo_2019_large_white.png"
                ></v-img>
              </a>
              <v-card-text class="text-justify"> </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </section>

    <v-footer color="#BED730">
      <v-layout row wrap align-center>
        <v-flex xs12>
          <div class="white--text ml-4">
            Made with
            <v-icon class="red--text">mdi-heart</v-icon>
            by
            <a class="white--text" href="http://dvisionlab.com" target="_blank"
              >D/Vision Lab</a
            >
          </div>
        </v-flex>
      </v-layout>
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {};
  },
  methods: {
    openViewer() {
      this.$router.push({ name: "annotator" });
      this.$store.dispatch("setCurrentPage", "annotator");
    }
  }
};
</script>

<style scoped>
.v-application a {
  text-decoration: none;
}
.color-dv {
  color: #bed730 !important;
}
</style>
