<template>
  <v-row dense>
    <v-divider vertical class="ma-0 pa-0"></v-divider>
    <v-col :cols="currentPage == 'annotator' ? 4 : 12">
      <v-slider
        hint="Brush dimension"
        persistent-hint
        dense
        min="1"
        max="500"
        step="10"
        prepend-icon="mdi-brush"
        :thumb-size="30"
        v-model="brushRadius"
        thumb-label
      >
        <template v-slot:thumb-label="{ value }"> {{ value }} </template>
      </v-slider>
    </v-col>
    <v-col cols="4" :hidden="currentPage == 'annotator' ? false : true">
      <v-slider
        hint="Mask opacity"
        persistent-hint
        dense
        min="0"
        max="1"
        step="0.1"
        prepend-icon="mdi-opacity"
        :thumb-size="30"
        v-model="opacity"
        thumb-label
      >
        <template v-slot:thumb-label="{ value }"> {{ value }} </template>
      </v-slider>
    </v-col>
    <v-divider vertical class="ma-0 pa-0"></v-divider>
  </v-row>
</template>

<script>
import { setBrushProps, setMaskProps } from "@/common/api.segmentation.js";
import { mapState } from "vuex";

export default {
  name: "SegmentationOptions",
  data() {
    return {
      opacity: 0.5,
      brushRadius: 50
    };
  },
  mounted() {
    const el = document.getElementById("viewer");
    el.onwheel = this.brushWheel;
  },
  beforeDestroy() {
    const el = document.getElementById("viewer");
    el.onwheel = null;
  },
  computed: {
    ...mapState({
      currentPage(state) {
        return state.currentPage;
      }
    })
  },
  methods: {
    brushWheel(event) {
      event.preventDefault();
      if (event.deltaY > 0) {
        this.brushRadius =
          this.brushRadius == 500 ? this.brushRadius : this.brushRadius + 10;
        setBrushProps({ radius: this.brushRadius });
      }
      if (event.deltaY < 0) {
        this.brushRadius =
          this.brushRadius == 10 ? this.brushRadius : this.brushRadius - 10;
        setBrushProps({ radius: this.brushRadius });
      }
    }
  },
  watch: {
    opacity: function(v) {
      let maskProps = {
        labelId: 0,
        tag: "",
        visualization: 0, // filled
        opacity: v
      };
      setMaskProps(maskProps, "viewer");
    },
    brushRadius: function(v) {
      setBrushProps({ radius: v });
    }
  }
};
</script>
