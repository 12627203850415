// external libraries

function initialState() {
  return {
    activeTool: null
  };
}

const state = {
  ...initialState()
};

const mutations = {
  reset(state) {
    // acquire initial state
    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  setActiveTool(state, value) {
    state.activeTool = value;
  }
};

const actions = {
  setActiveTool({ commit }, value) {
    commit("setActiveTool", value);
  }
};

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
