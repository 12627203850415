// external libraries

// internal libraries

function initialState() {
  return {
    activeItem: null,
    annotationList: []
  };
}

const state = {
  ...initialState()
};

const mutations = {
  reset(state) {
    // acquire initial state
    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  setActiveItem(state, value) {
    state.activeItem = value;
  },
  setAnnotationList(state, value) {
    state.annotationList = value;
  }
};

const actions = {
  setActiveItem({ commit }, value) {
    commit("setActiveItem", value);
  },
  setAnnotationList({ commit }, value) {
    commit("setAnnotationList", value);
  }
};

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
