// external libraries
import Vue from "vue";

function initialState() {
  return {
    isLoading: false,
    selectedTask: { tag: null, id: null },
    selectedItem: null,
    files: []
  };
}

const state = {
  ...initialState()
};

const mutations = {
  reset(state) {
    // acquire initial state
    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  setSelectedTask(state, value) {
    state.selectedTask = value;
  },
  setSelectedItem(state, value) {
    state.selectedItem = value;
  },
  setIsLoading(state, value) {
    state.isLoading = value;
  },
  setFilesList(state, value) {
    state.files = value;
  },
  setNextFile(state) {
    if (state.selectedItem !== null) {
      let fileObj = state.files[state.selectedItem];
      fileObj.annotated = 2;
      Vue.set(state.files, state.selectedItem, fileObj); // force re-render
      state.selectedItem++;
    }
  }
};

const actions = {
  setSelectedTask({ commit }, value) {
    commit("setSelectedTask", value);
  },
  setIsLoading({ commit }, value) {
    commit("setIsLoading", value);
  },
  setSelectedItem({ commit }, value) {
    commit("setSelectedItem", value);
  },
  setFilesList({ commit }, value) {
    commit("setFilesList", value);
  },
  setNextFile({ commit }) {
    commit("setNextFile");
  }
};

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
