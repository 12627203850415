var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"h100",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"h100"},[_c('v-btn',{staticClass:"secondary--text",attrs:{"color":"primary","dark":"","block":""},on:{"click":_vm.generatebatch}},[_c('v-icon',{staticClass:"mr-4"},[_vm._v("mdi-image-move")]),_vm._v(" Generate new "),_c('br'),_vm._v(" image batch ")],1),_c('TaskSelect'),_c('v-card',[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v("Files To Annotate")]),_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((_vm.files),function(file,i){return _c('v-list-item',{key:i,attrs:{"disabled":file.annotated == 2}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.getIcon(file.annotated))}})],1),_c('v-list-item-content',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-list-item-title',_vm._g({staticClass:"font-weight-light text-left",domProps:{"textContent":_vm._s(
                      '...' +
                        file.png
                          .split('/')
                          .slice(-2)
                          .join('/')
                    )}},Object.assign({}, tooltip)))]}}],null,true)},[_c('span',[_vm._v(_vm._s(file.png))])])],1)],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }