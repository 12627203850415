<template>
  <div class="h100">
    <v-card class="mx-auto h100">
      <v-card-text>
        <v-simple-table dark dense>
          <template v-slot:default>
            <tbody>
              <tr
                v-for="item in annotations"
                :key="item.id"
                @click="showData(item)"
              >
                <td>{{ item.name }}</td>
                <td>{{ item.data }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["fileId", "annotations"],
  components: {},
  computed: {},
  watch: {
    fileId: function(value) {
      console.log(value);
    }
  },
  methods: {
    showData: function(item) {
      console.log(item);
    }
  }
};
</script>

<style scoped>
.h100 {
  height: 100%;
}
.mt5 {
  margin-top: 5px;
}
</style>
