import { cornerstone, cornerstoneTools } from "larvitar";
import { cloneDeep, extend, values } from "lodash";

const segModule = cornerstoneTools.getModule("segmentation");
const setters = segModule.setters;
const getters = segModule.getters;

// DEV
window.segModule = segModule;
window.cornerstone = cornerstone;
window.cornerstoneTools = cornerstoneTools;

/**
 * NOTES ON CS TOOLS SEGMENTATION MODULE
 * The value in the mask (binary) define which color will be used from the LUT map
 * The different masks are 'labelmap', while different values in the same mask are 'segments'
 * Segments get the color from the lutmap (up to 2^16 segments) and can be shown/hidden one by one
 * > setters.toggleSegmentVisibility(htmlelement,segmentvalue,labelmapid)
 * > setters.colorForSegmentIndexOfColorLUT(colorLutIndex, segmentValue, colorRGBAarray)
 * Labelmaps are linked to a colormap and can be active / inactive
 * */

// General segmentation cs tools module configuration
const config = {
  arrayType: 0,
  renderOutline: false,
  renderFill: true,
  shouldRenderInactiveLabelmaps: true,
  radius: 50,
  minRadius: 1,
  maxRadius: 500,
  segmentsPerLabelmap: 255,
  fillAlpha: 0.5,
  fillAlphaInactive: 0.5,
  outlineAlpha: 1.0,
  outlineAlphaInactive: 1.0,
  outlineWidth: 1,
  storeHistory: true
};

// ====================================================
// utils to convert from hex to rgb and vice-versa ====
// ====================================================

function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

export function rgbToHex(c) {
  let r = componentToHex(c[0]);
  let g = componentToHex(c[1]);
  let b = componentToHex(c[2]);
  return "#" + r + g + b;
}

export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
      ]
    : null;
}

/**
 * Force cs tools refresh on all enabled images
 */
export function forceRender() {
  let enabledElement = cornerstone
    .getEnabledElements()
    .slice()
    .pop();
  if (enabledElement) {
    cornerstone.updateImage(enabledElement.element);
  }
}

/**
 * Generate the custom LUT - single volume version
 * detach store, use params
 */

// function generateLUT() {
//   let lut = new Array(2 ** 16);
//   lut.fill([0, 0, 0, 0]);

//   for (let tag in MAP_VALUES) {
//     let value = MAP_VALUES[tag];

//     if (!value) continue;

//     let opacity =
//       store.state.segmentations[tag].visualization == 1
//         ? 0
//         : Math.round(store.state.segmentations[tag].opacity * 255);

//     let color = hexToRgb(store.state.segmentations[tag].color);

//     lut[value] = color.concat(opacity);
//   }

//   return lut;
// }

/**
 * Generate the custom LUT - multiple volume version
 * @param {String} hex_color - color for LUT in the #RRGGBB form
 * @param {NUmber} opacity - number between 0 and 1
 */
function generateLUT(hex_color, opacity) {
  let lut = new Array(config.segmentsPerLabelmap);
  let rgb_color = hexToRgb(hex_color);
  let rgba_color = rgb_color.concat(Math.round(opacity * 255));
  lut.fill(rgba_color);
  return lut;
}

/**
 * A function to group all settings to load before masks
 */
export function initSegmentationModule() {
  // set configuration
  segModule.configuration = cloneDeep(config);
}

/**
 * Add 3D segmentation mask to segmentation module
 * @param {Object} props - {label: label index (must be unique), color: hex string, opacity: number[0,1]}
 * @param {TypedArray} data - The mask data array
 * @param {String} elementId - The target html element id
 * @param {Function} callback - Called without any param
 */
export function addSegmentationMask(props, data, elementId, callback) {
  let element = document.getElementById(elementId);
  let customLUT = generateLUT(props.color, props.opacity);
  setters.colorLUT(props.labelId, customLUT);

  // FIXME -  HACK to fix segmentation on png images: otherwise imageIds is a single string
  let stackState = cornerstoneTools.getToolState(element, "stack");
  stackState.data[0].imageIds = [stackState.data[0].imageIds];
  // end FIXME

  setters.labelmap3DForElement(element, data.buffer, props.labelId);

  // bind labelmap to colorLUT
  let labelmap3d = getters.labelmap3D(element, props.labelId);
  setters.colorLUTIndexForLabelmap3D(labelmap3d, props.labelId);
  if (callback) {
    callback();
  }
}

/**
 * Activate a specific labelmap through its labelId
 * @param {Number} labelId - The labelmap id to activate
 * @param {String} elementId - The target html element id
 */
export function setActiveLabelmap(labelId, elementId) {
  let element = document.getElementById(elementId);
  setters.activeLabelmapIndex(element, labelId);
  setters.activeSegmentIndex(element, labelId);
}

/**
 * Change opacity for active label
 * @param {Number} opacity - The desired opacity value
 */
export function setActiveLabelOpacity(opacity) {
  segModule.configuration.fillAlpha = opacity;
  forceRender();
}

/**
 * Change opacity for inactive labels
 * @param {Number} opacity - The desired opacity value
 */
export function setInactiveLabelOpacity(opacity) {
  segModule.configuration.fillAlphaInactive = opacity;
  forceRender();
}

/**
 * Toggle between 'contours mode' and 'filled mode'
 * @param {Bool} toggle - Contour mode enabled if true
 */
export function toggleContourMode(toggle) {
  if (toggle) {
    segModule.configuration.fillAlpha = 0.0;
    segModule.configuration.fillAlphaInactive = 0.0;
    segModule.configuration.outlineAlpha = 1.0;
    segModule.configuration.outlineAlphaInactive = 1.0;
    segModule.configuration.outlineWidth = 3;
  } else {
    segModule.configuration.fillAlpha = config.fillAlpha;
    segModule.configuration.fillAlphaInactive = config.fillAlphaInactive;
    segModule.configuration.outlineAlpha = config.outlineAlpha;
    segModule.configuration.outlineAlphaInactive = config.outlineAlphaInactive;
    segModule.configuration.outlineWidth = config.outlineWidth;
  }
  forceRender();
}

/**
 * Set mask appearance props
 * @param {Obejct} props -{
 *                          labelId: the label id [Number],
 *                          tag: The mask tag [string],
 *                          visualization: 0=filled, 1=contour, 2=hidden [Number],
 *                          opacity: Opacity value [0-1 Number]
 *                          }
 * @param {String} elementId - the target element id
 */
export function setMaskProps(props, elementId) {
  // Lut index and segment values are hardcoded because they will depend on design choices:
  // eg single/multiple volumes for segmentations
  let lutIndex = props.labelId;
  let labelIndex = props.labelId;
  // let segmentValue = MAP_VALUES[props.id];
  let segmentValue = 255; // binary mask, segment 1 is the only to be affected by color & opacity
  let currentColor = getters.colorForSegmentIndexColorLUT(
    props.labelId,
    segmentValue
  );
  let htmlelement = document.getElementById(elementId);

  let newColor = currentColor;
  switch (props.visualization) {
    // full
    case 0:
      segModule.configuration.renderOutline = true;
      getters.isSegmentVisible(htmlelement, segmentValue, labelIndex)
        ? null
        : setters.toggleSegmentVisibility(
            htmlelement,
            segmentValue,
            labelIndex
          );
      newColor[3] = Math.round(props.opacity * 255);
      setters.colorForSegmentIndexOfColorLUT(lutIndex, segmentValue, newColor);
      break;
    // contours
    case 1:
      segModule.configuration.renderOutline = true;
      getters.isSegmentVisible(htmlelement, segmentValue, labelIndex)
        ? null
        : setters.toggleSegmentVisibility(
            htmlelement,
            segmentValue,
            labelIndex
          );
      newColor[3] = 0;
      setters.colorForSegmentIndexOfColorLUT(lutIndex, segmentValue, newColor);
      break;
    // hidden
    case 2:
      setters.toggleSegmentVisibility(htmlelement, segmentValue, labelIndex);
      break;
  }
  forceRender();
}

/**
 * Clear state for segmentation module
 */
export function clearSegmentationState() {
  segModule.state.series = {};
}

/**
 * Change the brush props
 * @param {Object} props - The new brush props {radius: number[px], thresholds: array[min,max]}
 */
export function setBrushProps(props) {
  extend(segModule.configuration, props);
  forceRender();
}

/**
 * Retrieve the buffer that represents the current active mask (3D)
 * @param {String} elementId - The target html element id
 */
export function getActiveLabelmapBuffer(elementId) {
  let element = document.getElementById(elementId);
  let object = segModule.getters.activeLabelmapBuffer(element);
  return object.buffer;
}

/**
 * Retrieve the buffer that represents the current active mask (2D)
 * @param {String} elementId - The target html element id
 */
export function getActive2DLabelmapBuffer(elementId) {
  let element = document.getElementById(elementId);
  let object = segModule.getters.labelmap2D(element);
  return object.labelmap2D.pixelData;
}

/**
 * Undo last brush operation (stroke)
 * @param {String} elementId - The target html element id
 */
export function undoLastStroke(elementId) {
  let element = document.getElementById(elementId);
  let activeLabelMapIndex = segModule.getters.activeLabelmapIndex(element);
  setters.undo(element, activeLabelMapIndex);
}

/**
 * Redo last brush operation (stroke)
 * @param {String} elementId - The target html element id
 */
export function redoLastStroke(elementId) {
  let element = document.getElementById(elementId);
  let activeLabelMapIndex = segModule.getters.activeLabelmapIndex(element);
  setters.redo(element, activeLabelMapIndex);
}

/**
 * Delete mask from state
 */
export function deleteMask(labelId) {
  let masks = values(segModule.state.series)[0].labelmaps3D;
  delete masks[labelId];
  forceRender();
}

/**
 * Check if element is enabled
 * @param {String} elementId - The target html element id
 * @return {Bool}
 */
export function isElementEnabled(elementId) {
  let isElementEnabled = cornerstone
    .getEnabledElements()
    .map(e => e.element.id)
    .includes(elementId);

  return isElementEnabled;
}
