<template>
  <div>
    <v-app>
      <template v-if="currentPage != 'home'">
        <v-app-bar app dark height="55">
          <router-view name="header" />
        </v-app-bar>
      </template>
      <v-main>
        <router-view />
      </v-main>
      <template v-if="currentPage != 'home'">
        <v-footer app height="100">
          <router-view name="footer" />
        </v-footer>
      </template>
    </v-app>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { without } from "lodash";

// fonts
import "typeface-roboto";

// multilanguage
import i18n from "@/plugins/i18n";

export default {
  name: "App",
  data() {
    return {
      languages: ["en", "it"],
      language: "en"
    };
  },
  computed: {
    ...mapState({
      currentPage(state) {
        return state.currentPage;
      }
    })
  },
  methods: {
    switchLocale() {
      let language = without(this.languages, this.language)[0];
      this.language = language;
      i18n.locale = language;
    }
  }
};
</script>

<style>
body {
  font-family: "roboto";
}
html {
  overflow: auto !important;
}
.v-application {
  height: 100vh;
}
.v-main {
  height: 100%;
}
.h100 {
  height: 100%;
}
.h50 {
  height: 50%;
}
.w100 {
  width: 100%;
}
.w-50 {
  width: 50%;
}
.color-dv {
  color: #bed730 !important;
}
.fa-disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
</style>
