// external libraries
import { filter } from "lodash";

function initialState() {
  return {
    activeItem: null,
    data: [],
    tree: []
  };
}

const state = {
  ...initialState()
};

const mutations = {
  reset(state) {
    // acquire initial state
    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  addData(state, value) {
    state.data.push(value);
  },
  addFolder(state, value) {
    state.tree.push(value);
  },
  // TODO SAME FOR DATA ARRAY?
  removeFolder(state, value) {
    state.tree = filter(state.tree, function(el) {
      return el.id != value;
    });
  },
  resetFolders(state) {
    state.tree = [];
    state.data = [];
  },
  setActiveItem(state, value) {
    state.activeItem = value;
  }
};

const actions = {
  addData({ commit }, value) {
    commit("addData", value);
  },
  addFolder({ commit }, value) {
    commit("addFolder", value);
  },
  removeFolder({ commit }, value) {
    commit("removeFolder", value);
  },
  resetFolders({ commit }) {
    commit("resetFolders");
  },
  setActiveItem({ commit }, value) {
    commit("setActiveItem", value);
  }
};

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
