import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import Annotator from "@/views/Annotator.vue";
import AnnotatorGmm from "@/views/AnnotatorGmm.vue";

import Header from "@/components/Header/Header.vue";
import Footer from "@/components/Footer/Footer.vue";
import FooterGmm from "@/components/Footer/FooterGmm.vue";

Vue.use(VueRouter);

window.popStateDetected = false;
window.addEventListener("popstate", () => {
  window.popStateDetected = true;
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const routes = [
  {
    path: "/",
    name: "home",
    components: {
      default: Home
    },
    beforeEnter: (_to, _from, next) => {
      const navigated = window.popStateDetected;
      window.popStateDetected = false;
      next(!navigated);
    }
  },
  {
    path: "/annotator",
    name: "annotator",
    components: {
      header: Header,
      default: Annotator,
      footer: Footer
    },
    beforeEnter: (_to, _from, next) => {
      const navigated = window.popStateDetected;
      window.popStateDetected = false;
      next(!navigated);
    }
  },
  {
    path: "/gmm",
    name: "gmm",
    meta: { auth: true, autoLogin: true },
    components: {
      header: Header,
      default: AnnotatorGmm,
      footer: FooterGmm
    },
    beforeEnter: (_to, _from, next) => {
      const navigated = window.popStateDetected;
      window.popStateDetected = false;
      next(!navigated);
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: __dirname,
  routes
});

export default router;
