<template>
  <div :id="name" class="hw100"></div>
</template>

<script>
import {
  initLarvitarStore,
  initializeFileImageLoader,
  larvitar_store
} from "larvitar";

initializeFileImageLoader();
initLarvitarStore();
larvitar_store.addViewport("viewer");

export default {
  data() {
    return {};
  },
  props: ["name"],
  computed: {},
  methods: {}
};
</script>

<style scoped>
.hw100 {
  height: 100%;
  width: calc(100% - 2px);
}
</style>
