<template>
  <v-container height="100%" fluid>
    <a href="http://www.dvisionlab.com" target="_blank">
      <v-img
        src="/media/images/dvision_logo_2019_symbol.png"
        height="45"
        width="100"
        max-width="100"
        class="logo-brand"
        contain
      >
      </v-img>
    </a>

    <v-divider vertical class="mx-4"></v-divider>

    <v-row align="center" justify="center">
      <v-col>
        <toolsBar />
      </v-col>
    </v-row>

    <v-divider vertical class="mx-4"></v-divider>

    <span class="version"> {{ appVersion }} </span>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { version } from "@/../package.json";

import toolsBar from "@/components/Header/ToolsBar.vue";

export default {
  data() {
    return {};
  },
  components: {
    toolsBar
  },
  computed: {
    ...mapState({}),
    appVersion: function() {
      return "v" + version;
    }
  },
  watch: {},
  methods: {}
};
</script>

<style scoped>
.w100 {
  width: 100%;
}
.h100 {
  height: 100%;
}
.logo-brand {
  float: left;
  margin: 15px;
}
.logo-product {
  float: right;
  margin-left: 10px;
}
.version {
  float: right;
  right: 15px;
  top: 20px;
  position: absolute;
  font-size: medium;
}
.v-application a {
  color: transparent !important;
}
</style>
