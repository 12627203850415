<template>
  <div class="viewer-container">
    <template>
      <v-row no-gutters class="text-center singleview-row">
        <v-col sm="3" class="h100 scroll">
          <LeftSidePanelGmm />
        </v-col>
        <v-col sm="9" class="singleview-container">
          <viewer name="viewer" />
        </v-col>
      </v-row>
    </template>
    <v-overlay :value="isLoading">
      <v-progress-circular
        class="color-dv"
        indeterminate
        size="100"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import viewer from "@/components/Viewer/Viewer.vue";
import LeftSidePanelGmm from "@/components/SidePanels/LeftSidePanelGmm.vue";
import { mapState } from "vuex";

export default {
  name: "AnnotatorGmm",
  data() {
    return {};
  },
  components: {
    viewer,
    LeftSidePanelGmm
  },
  computed: {
    ...mapState({
      viewer(state) {
        return state.currentPage;
      },
      isLoading(state) {
        return state.gmm.isLoading;
      }
    })
  },
  mounted() {
    this.$store.dispatch("setCurrentPage", "gmm");
  },
  methods: {}
};
</script>

<style scoped>
.viewer-container {
  height: 100%;
}
.singleview-row {
  height: 100%;
  margin-right: 5px;
}
.singleview-container {
  border: 1px solid #bed730;
  height: 100%;
}
.color-dv {
  color: #bed730 !important;
}
.scroll {
  overflow: auto;
}
</style>
