<template>
  <div class="h100-header">
    <v-toolbar dense elevation="0">
      <v-toolbar-title>Annotations</v-toolbar-title>
    </v-toolbar>
    <div class="h70 scrollable">
      <AnnotationList :fileId="fileId" :annotations="annotations" />
    </div>
    <div class="h30 bt1">
      <AnnotationMetadata :fileId="fileId" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import AnnotationList from "@/components/SidePanels/Right/AnnotationList";
import AnnotationMetadata from "@/components/SidePanels/Right/AnnotationMetadata";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      fileId(state) {
        return state.annotations.activeItem;
      },
      annotations(state) {
        return state.annotations.annotationList;
      }
    })
  },
  components: {
    AnnotationList,
    AnnotationMetadata
  },
  watch: {},
  methods: {}
};
</script>

<style scoped>
.h100-header {
  height: calc(100% - 37px);
}
.center-text {
  text-align: center;
}
.scrollable {
  overflow-y: auto;
}
.h70 {
  height: 70%;
}
.h30 {
  height: 30%;
}
.bt1 {
  border-top: 1px solid #bed730;
}
</style>
