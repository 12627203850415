<template>
  <div class="text-center">
    <template v-if="currentPage == 'annotator'">
      <v-menu open-on-hover offset-y max-height="50%">
        <template v-slot:activator="{ on: menu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                dark
                :color="menuColor('annotation')"
                v-on="{ ...tooltip, ...menu }"
                class="toolsbarbutton"
              >
                <v-icon class="pr-2">mdi-draw</v-icon>
                {{
                  selectedTool && selectedTool.type == "annotation"
                    ? selectedTool.name
                    : "Annotation"
                }}
              </v-btn>
            </template>
            <span>Description</span>
          </v-tooltip>
        </template>

        <v-list>
          <v-list-item
            v-for="(item, index) in annotationTools"
            :key="index"
            @click="setSelectedTool(item)"
          >
            <v-tooltip left>
              <template v-slot:activator="{ on: tooltip }">
                <v-list-item-title v-on="{ ...tooltip }">{{
                  item.name
                }}</v-list-item-title>
                <v-list-item-subtitle
                  align="right"
                  class="font-italic font-weight-light pa-1"
                  >{{ item.shortcut }}</v-list-item-subtitle
                >
              </template>
              <span>{{ item.description }}</span>
            </v-tooltip>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <!-- </v-col>

      <v-col cols="4" class="toolsbar"> -->
    <v-menu open-on-hover offset-y max-height="50%">
      <template v-slot:activator="{ on: menu }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              :color="menuColor('segmentation')"
              v-on="{ ...tooltip, ...menu }"
              class="toolsbarbutton"
            >
              <v-icon class="pr-2">mdi-brush</v-icon>
              {{
                selectedTool && selectedTool.type == "segmentation"
                  ? selectedTool.name
                  : "Segmentation"
              }}
            </v-btn>
          </template>
          <span>Description</span>
        </v-tooltip>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, index) in segmentationTools"
          :key="index"
          @click="setSelectedTool(item)"
        >
          <v-tooltip left>
            <template v-slot:activator="{ on: tooltip }">
              <v-list-item-title v-on="{ ...tooltip }">{{
                item.name
              }}</v-list-item-title>
              <v-list-item-subtitle
                align="right"
                class="font-italic font-weight-light pa-1"
                >{{ item.shortcut }}</v-list-item-subtitle
              >
            </template>
            <span>{{ item.description }}</span>
          </v-tooltip>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu open-on-hover offset-y max-height="50%">
      <template v-slot:activator="{ on: menu }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              :color="menuColor('utils')"
              v-on="{ ...tooltip, ...menu }"
              class="toolsbarbutton"
            >
              <v-icon class="pr-2">mdi-magnify</v-icon>
              {{
                selectedTool && selectedTool.type == "utils"
                  ? selectedTool.name
                  : "Utils"
              }}
            </v-btn>
          </template>
          <span>Description</span>
        </v-tooltip>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, index) in utilsTools"
          :key="index"
          @click="setSelectedTool(item)"
        >
          <v-tooltip left>
            <template v-slot:activator="{ on: tooltip }">
              <v-list-item-title v-on="{ ...tooltip }">{{
                item.name
              }}</v-list-item-title>
              <v-list-item-subtitle
                align="right"
                class="font-italic font-weight-light pa-1"
                >{{ item.shortcut }}</v-list-item-subtitle
              >
            </template>
            <span>{{ item.description }}</span>
          </v-tooltip>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  initializeCSTools,
  setToolActive,
  setToolDisabled,
  getDefaultToolsByType,
  cornerstoneTools,
  setDefaultToolsProps
} from "larvitar";

import { initSegmentationModule } from "@/common/api.segmentation.js";
import { tools_config } from "@/config/gmm.config";

initializeCSTools("viewer");
// override default tools props
setDefaultToolsProps(tools_config);
initSegmentationModule();

window.cornerstoneTools = cornerstoneTools;

export default {
  data() {
    return {
      selTool: null,
      selectedTool: null,
      annotationTools: Object.values(getDefaultToolsByType("annotation")),
      segmentationTools: Object.values(getDefaultToolsByType("segmentation")),
      utilsTools: Object.values(getDefaultToolsByType("utils"))
    };
  },
  mounted() {
    this.utilsTools.push({
      name: "No-Tool",
      description: "Remove tool selection",
      shortcut: "ctrl-n",
      type: "utils"
    });
  },
  computed: {
    ...mapState({
      currentPage(state) {
        return state.currentPage;
      }
    })
  },
  methods: {
    setSelectedTool(tool) {
      if (tool.name == "No-Tool") {
        let currentTool = this.selectedTool;
        if (currentTool) {
          setToolDisabled(currentTool.name);
          this.selectedTool = null;
        }
      } else {
        this.selectedTool = tool;
        setToolActive(this.selectedTool.name);
      }
    },
    menuColor(menuId) {
      let color =
        menuId == (this.selectedTool && this.selectedTool.type)
          ? "#BED730"
          : null;
      return color;
    }
  }
};
</script>

<style scoped>
.toolsbarbutton {
  width: 30%;
  margin: 2px;
}
</style>
