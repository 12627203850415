<template>
  <v-row class="h100">
    <v-col cols="4">
      <input
        ref="uploader"
        class="d-none"
        type="file"
        @change="onFilesChange"
      />
      <template v-if="Object.keys(metadata).length > 0">
        <p class="metadata"><b>File Path:</b> {{ metadata.path }}</p>
        <p class="metadata">
          <b>Image Size:</b> {{ metadata.width }} * {{ metadata.height }} pixels
        </p>
        <p class="metadata">
          <b>Image Type and Dimensions:</b> {{ metadata.type }} -
          {{ metadata.size }} Kb
        </p>
      </template>
    </v-col>
    <v-col cols="4">
      <SegmentationOptions />
    </v-col>

    <v-col cols="4" class="footerbuttons" align="right">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            color="primary"
            dark
            class="secondary--text ma-2"
            v-on="{ ...tooltip }"
            @click="loadState"
            :disabled="imagesLoaded"
          >
            <v-icon class="pr-2">mdi-upload</v-icon>
            Load
          </v-btn>
        </template>
        <span>Load state as json file</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            light
            color="primary"
            dark
            class="secondary--text ma-2"
            v-on="{ ...tooltip }"
            @click="saveState"
            :disabled="imagesLoaded"
          >
            <v-icon class="pr-2">mdi-download</v-icon>
            Save
          </v-btn>
        </template>
        <span>Save state as json file</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            light
            color="primary"
            dark
            class="secondary--text ma-2"
            v-on="{ ...tooltip }"
            @click="exportState"
            :disabled="imagesLoaded"
          >
            <v-icon class="pr-2">mdi-file-delimited</v-icon>
            Export
          </v-btn>
        </template>
        <span>Export annotations as CSV</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import SegmentationOptions from "@/components/Footer/SegmentationOptions.vue";

import { saveAnnotations, loadAnnotations, exportAnnotations } from "larvitar";

export default {
  name: "Footer",
  data() {
    return {};
  },
  components: {
    SegmentationOptions
  },
  computed: {
    ...mapState({
      imagesLoaded(state) {
        return state.treeview.tree.length == 0;
      },
      metadata(state) {
        return state.metadata.metadata;
      }
    })
  },
  methods: {
    saveState() {
      saveAnnotations(true);
    },
    loadState() {
      this.files = [];
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    exportState() {
      exportAnnotations(this.$store.state.fileManager);
    },
    onFilesChange: function(e) {
      this.files = [...e.target.files];

      let reader = new FileReader();
      reader.readAsText(this.files.pop());

      reader.onerror = err => {
        console.error(err);
        alert("error");
      };

      reader.onloadend = e => {
        let data;
        try {
          data = JSON.parse(e.target.result);
        } catch (err) {
          console.error(err);
          alert("cannot parse loaded file");
          return;
        }

        loadAnnotations(data);
      };

      this.$refs.uploader.value = null;
    }
  }
};
</script>

<style scoped>
.h100 {
  height: 100%;
}
.footerbuttons {
  vertical-align: middle;
}
.metadata {
  margin: 0px;
  width: 100%;
}
</style>
