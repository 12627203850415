// external libraries
import { cornerstone, cornerstoneTools } from "larvitar";
import { each, has, throttle } from "lodash";

// internal libraries
import store from "@/store/index";

// ======================================================
// Get Annotations object list for a specific imageId ===
// ======================================================
export function storeImageMetadata(itemFile, fileName) {
  let image = cornerstone.getImage(document.getElementById("viewer"));
  let metadata = {
    size: (itemFile.size / 1000).toFixed(1), //Kb
    path: has(itemFile, "webkitRelativePath")
      ? itemFile.webkitRelativePath
      : itemFile.name,
    type: itemFile.type,
    height: image.height,
    width: image.width,
    name: fileName ? fileName : null
  };
  store.dispatch("metadata/setMetadata", metadata);
}

// ======================================================
// Get Annotations object list for a specific imageId ===
// ======================================================
export function getAnnotations(imageId) {
  let annotations = has(
    cornerstoneTools.globalImageIdSpecificToolStateManager.toolState,
    imageId
  )
    ? cornerstoneTools.globalImageIdSpecificToolStateManager.toolState[imageId]
    : {};
  let annotationsList = [];
  each(annotations, function(item, name) {
    each(item.data, function(data) {
      let annotation = {};
      annotation.name = name;
      annotation.id = data.uuid;
      if (name == "ArrowAnnotate") {
        annotation.data = data.text;
      } else {
        if (has(data, "cachedStats")) {
          annotation.data = data.cachedStats.area
            ? data.cachedStats.area.toFixed(2)
            : null;
        } else {
          annotation.data = data.area ? data.area.toFixed(2) : null;
        }
      }
      annotation.handles = data.handles;
      annotationsList.push(annotation);
    });
  });
  return annotationsList;
}

export function storeAnnotationData() {
  setTimeout(function() {
    let annotations = getAnnotations(store.state.annotations.activeItem);
    store.dispatch("annotations/setAnnotationList", annotations);
  }, 500);
}

// ======================================
// Start listening to cs tools events ===
// ======================================
export function startListeningCSTools(elementId) {
  let element = document.getElementById(elementId);
  // on new annotation
  element.addEventListener(
    "cornerstonetoolsmeasurementcompleted",
    storeAnnotationData
  );
  // on modified annotation
  let throttledSave = throttle(storeAnnotationData, 500); // save data max once every 500 ms when dragging
  element.addEventListener(
    "cornerstonetoolsmeasurementmodified",
    throttledSave
  );
  // on deleted annotation
  element.addEventListener(
    "cornerstonetoolsmeasurementremoved",
    storeAnnotationData
  );
}
