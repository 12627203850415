<template>
  <v-row class="h100">
    <v-col cols="4">
      <template v-if="Object.keys(metadata).length > 0">
        <p class="metadata"><b>File Path:</b> {{ metadata.path }}</p>
        <p class="metadata">
          <b>Image Size:</b> {{ metadata.width }} * {{ metadata.height }} pixels
        </p>
        <p class="metadata">
          <b>Image Type and Dimensions:</b> {{ metadata.type }} -
          {{ metadata.size }} Kb
        </p>
      </template>
    </v-col>
    <v-col cols="4">
      <SegmentationOptions />
    </v-col>
    <v-col cols="4" class="footerbuttons" align="right">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            color="primary"
            dark
            class="secondary--text ma-2"
            v-on="{ ...tooltip }"
            @click="undo"
          >
            <v-icon class="pr-2">mdi-undo</v-icon>
            Undo
          </v-btn>
        </template>
        <span>Undo last change</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            light
            color="primary"
            dark
            class="secondary--text ma-2"
            v-on="{ ...tooltip }"
            @click="redo"
          >
            <v-icon class="pr-2">mdi-redo</v-icon>
            Redo
          </v-btn>
        </template>
        <span>Redo last change</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            light
            color="primary"
            dark
            class="secondary--text ma-2"
            v-on="{ ...tooltip }"
            @click="accept"
            :disabled="selectedItem"
          >
            <v-icon class="pr-2">mdi-check</v-icon>
            Accept
          </v-btn>
        </template>
        <span>Save mask</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
import SegmentationOptions from "@/components/Footer/SegmentationOptions.vue";
import { mapState } from "vuex";
import {
  undoLastStroke,
  redoLastStroke,
  isElementEnabled
} from "@/common/api.segmentation.js";
import { saveMask } from "@/common/api.gmm.js";

export default {
  name: "FooterGmm",
  data() {
    return {};
  },
  components: {
    SegmentationOptions
  },
  computed: {
    ...mapState({
      imagesLoaded(state) {
        return state.treeview.tree.length == 0;
      },
      metadata(state) {
        return state.metadata.metadata;
      },
      selectedItem(state) {
        let isImagePresent = state.gmm.selectedItem == null ? true : false;
        return isImagePresent;
      }
    })
  },
  methods: {
    undo() {
      if (isElementEnabled("viewer")) {
        undoLastStroke("viewer");
      }
    },
    redo() {
      if (isElementEnabled("viewer")) {
        redoLastStroke("viewer");
      }
    },
    accept() {
      let maskId = this.$store.state.metadata.activeMask;
      let userConfirmation = confirm(
        "Are you sure? This action cannot be undone"
      );
      if (maskId && userConfirmation) {
        saveMask(maskId, savedOk => {
          if (savedOk) {
            // load next file
            this.$store.commit("gmm/setNextFile");
          } else {
            // TODO spawn error modal
          }
        });
      }
    }
  }
};
</script>

<style scoped>
.h100 {
  height: 100%;
}
.footerbuttons {
  vertical-align: middle;
}
.metadata {
  margin: 0px;
  width: 100%;
}
</style>
