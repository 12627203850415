<template>
  <div class="viewer-container">
    <template>
      <v-row no-gutters class="text-center singleview-row">
        <v-col sm="3">
          <LeftSidePanel />
        </v-col>
        <v-col sm="6" class="singleview-container">
          <viewer name="viewer" />
        </v-col>
        <v-col sm="3">
          <RightSidePanel />
        </v-col>
      </v-row>
    </template>
    <v-overlay :value="isLoading">
      <v-progress-circular
        class="color-dv"
        indeterminate
        size="100"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import viewer from "@/components/Viewer/Viewer.vue";
import LeftSidePanel from "@/components/SidePanels/LeftSidePanel.vue";
import RightSidePanel from "@/components/SidePanels/RightSidePanel.vue";

export default {
  name: "Annotator",
  data() {
    return {};
  },
  components: {
    viewer,
    LeftSidePanel,
    RightSidePanel
  },
  computed: {
    isLoading: function() {
      // TODO
      return false;
    }
  },
  mounted() {
    this.$store.dispatch("setCurrentPage", "annotator");
  },
  methods: {}
};
</script>

<style scoped>
.viewer-container {
  height: 100%;
}
.singleview-row {
  height: 100%;
}
.singleview-container {
  border: 0.5px solid #bed730;
  height: 100%;
}
.color-dv {
  color: #bed730 !important;
}
</style>
