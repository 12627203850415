<template>
  <div class="h100">
    <v-card flat class="h100 scrollable">
      <v-card-text class="h100">
        <v-container fluid class="h100">
          <v-toolbar dense elevation="10">
            <v-row>
              <v-col cols="4">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="fr"
                      color="#bed730"
                      icon
                      v-on="on"
                      dark
                      @click="addFolder"
                    >
                      <v-icon dark>mdi-import</v-icon>
                    </v-btn>
                  </template>
                  <span>Add a Folder</span>
                </v-tooltip>

                <input
                  ref="uploader"
                  class="d-none"
                  type="file"
                  multiple
                  webkitdirectory
                  @change="onFilesChange"
                />
              </v-col>
              <v-col cols="4">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="fr"
                      color="#bed730"
                      icon
                      v-on="on"
                      dark
                      @click="removeFolder"
                    >
                      <v-icon dark>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Remove a Folder</span>
                </v-tooltip>
              </v-col>
              <v-col cols="4">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="fr"
                      color="#bed730"
                      icon
                      v-on="on"
                      dark
                      @click="removeFolders"
                    >
                      <v-icon dark>mdi-restore</v-icon>
                    </v-btn>
                  </template>
                  <span>Remove all Folders</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-toolbar>

          <TreeView />
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import TreeView from "@/components/SidePanels/Left/TreeView";

import { buildData, buildTreeView } from "@/common/api.treeview";
import { startListeningCSTools } from "@/common/api.annotations";
import {
  DEFAULT_TOOLS,
  resetFileLoader,
  populateFileManager,
  fileManager
} from "larvitar";
import { each } from "lodash";

export default {
  name: "LeftSidePanel",
  data() {
    return {
      isSelecting: false,
      files: []
    };
  },
  components: {
    TreeView
  },
  computed: {},
  watch: {},
  methods: {
    addFolder: function() {
      this.files = [];
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    onFilesChange: function(e) {
      this.files = [...e.target.files];
      let data = buildData(this.files);
      this.$store.dispatch("treeview/addData", data);
      let treeView = buildTreeView(data);
      this.$store.dispatch("treeview/addFolder", treeView);
      this.$refs.uploader.value = null;
      startListeningCSTools("viewer");
      DEFAULT_TOOLS["StackScrollMouseWheel"].defaultActive = false; // remove wheel
      // addDefaultTools("viewer");
      each(this.files, function(image) {
        populateFileManager(image);
      });
      this.$store.dispatch("setFileManager", fileManager);
    },
    removeFolder: function() {
      this.$store.dispatch(
        "treeview/removeFolder",
        this.$store.state.treeview.activeItem
      );
      this.$store.dispatch("treeview/setActiveItem", null);
      this.$store.dispatch("annotations/setActiveItem", null);
    },
    removeFolders: function() {
      this.$store.dispatch("treeview/resetFolders");
      resetFileLoader("viewer");
    }
  }
};
</script>

<style scoped>
.v-navigation-drawer {
  overflow: hidden !important;
}
.w100 {
  width: 100%;
}
.h100 {
  height: 100%;
}
.h90 {
  height: 90%;
}
.h10 {
  height: 10%;
}
.center-text {
  text-align: center;
}
.scrollable {
  overflow-y: auto;
}
</style>
