<template>
  <v-select
    v-model="selectedTask"
    :hint="`tag: ${selectedTask.tag}, id: ${selectedTask.id}`"
    :items="taskList"
    item-text="tag"
    item-value="id"
    menu-props="auto"
    label="Select a task"
    single-line
    return-object
  ></v-select>
</template>

<script>
import { getTasksList } from "@/common/api.gmm.js";

export default {
  data() {
    return {
      taskList: []
    };
  },
  computed: {
    selectedTask: {
      get() {
        return this.$store.state.gmm.selectedTask;
      },
      set(value) {
        this.$store.commit("gmm/setSelectedTask", value);
        // TODO change list file according to selected task
      }
    }
  },
  mounted() {
    let self = this;
    getTasksList(content => {
      self.taskList = content.results;
    });
  }
};
</script>
