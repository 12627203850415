import Vue from "vue";
import request from "superagent";
import { basename } from "path";
import {
  populateFileManager,
  fileManager,
  renderFileImage,
  addDefaultTools,
  addMouseKeyHandlers
} from "larvitar";
import store from "@/store/index";
import {
  addSegmentationMask,
  forceRender,
  getActiveLabelmapBuffer,
  setActiveLabelmap
} from "./api.segmentation";
import { storeImageMetadata } from "./api.annotations";
import { mouse_key_config } from "../config/gmm.config";

import { PNG } from "pngjs/browser";

/**
 * Save current labelmap in DB as png file
 * @param {Number} maskId - mask id on DB
 * @param {Function} cb - callback, pass true if saving OK, false otherwise
 */

export function saveMask(maskId, cb) {
  let buffer = getActiveLabelmapBuffer("viewer");
  let file = new File([buffer], { type: "image/png" });

  request
    .put("/api/mask/" + maskId + "/")
    .field("height", store.state.metadata.metadata.height)
    .field("width", store.state.metadata.metadata.width)
    .field("mask_name", store.state.metadata.metadata.name)
    .attach("data", file)
    .then(res => {
      if (res.status == 200) {
        console.log("SAVED");
        cb(true);
      } else {
        console.error("Could not save annotations");
        cb(false);
      }
    });
}

/**
 * Get file from DB along with its mask and render all
 * @param {Object} image - {id, png, maskId}
 * @param {Function} callback
 */

export function importImageWithMask(image, callback) {
  request
    .get("/api/image/" + image.id + "/")
    .on("progress", function(event) {
      console.log(event.percent);
    })
    .then(res => {
      let content = JSON.parse(res.body);
      let fileContent = content[0].image;
      let filePath = "." + image.png.split("png_orig")[1];

      let file = dataURLtoFile(fileContent, filePath, "text/plain");

      store.dispatch("metadata/setActiveImage", image.id);
      store.dispatch("metadata/setActiveMask", image.maskId);

      populateFileManager(file);
      store.dispatch("setFileManager", fileManager);

      renderFileImage(file, "viewer", function(img) {
        storeImageMetadata(file, basename(image.png));

        addDefaultTools("viewer");
        addMouseKeyHandlers(mouse_key_config, ["viewer"]);

        // LAUNCH PRE ANNOTATION
        // TODO: check if preannotation is available for current task
        preannotate(resp => {
          let png = new PNG({ colorType: 0 });
          png.parse(resp.body, function(error, data) {
            let maskData = new Uint16Array(img.rows * img.columns).fill(0);

            // RGBA: keep only R channel
            for (let i = 0; i < maskData.length; i++) {
              if (data.data[i * 4] == 255) {
                maskData[i] = 255;
              }
            }

            let props = { color: "#00FF00", opacity: 0.5, labelId: 255 };
            let elementId = "viewer";
            addSegmentationMask(props, maskData, elementId, () => {
              setActiveLabelmap(props.labelId, elementId);
              forceRender();
              callback();
            });
          });
        });
      });
    });
}

/**
 * Read base64 string as typed array
 * @param {String} data - base64 string
 * @param {String} filename - builded file name
 * @returns
 */

function dataURLtoFile(data, filename) {
  let bstr = atob(data);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: "image/png" });
}

/**
 * GET tasks list
 * @param {Function} cb - callback
 */
export function getTasksList(cb) {
  Vue.$http
    .get("/api/tasks/")
    .then(resp => {
      cb(resp);
    })
    .catch(error => {
      cb(error);
    });
}

/**
 * GET files list to annotate
 * @param {String} task - tag of the task to annotate
 * @param {Function} cb - callback
 */
export function getFilesList(task, cb) {
  request
    .get("/api/task/" + task.id + "/images/")
    .then(resp => {
      let results = resp.body.results;
      results = results.map(l => {
        l.image.annotated = 0;
        return { maskId: l.id, ...l.image };
      });
      cb(results);
    })
    .catch(error => {
      cb(error);
    });
}

/**
 * Run png generation task
 * @param {Function} callback
 */
export function generatepng(callback) {
  request.get("/api/generatepng/").then(res => {
    if (res.status == 200) {
      callback();
      console.log("OK");
    } else {
      callback();
      console.error("ERROR");
    }
  });
}

/**
 * Run preannotation task
 * @param {Function} callback
 */
export function preannotate(callback) {
  let data = {
    mask_id: store.state.metadata.activeMask,
    image_id: store.state.metadata.activeImage,
    height: store.state.metadata.metadata.height,
    width: store.state.metadata.metadata.width,
    task: store.state.gmm.selectedTask.tag
  };
  request
    .post("/api/preannotate/")
    .responseType("arraybuffer")
    .send(data)
    .then(res => {
      if (res.status == 200) {
        callback(res);
      } else {
        callback(res);
      }
    });
}

window.preannotate = preannotate;
