<template>
  <div class="h100">
    <v-card flat class="h100 scrollable">
      <v-card-text class="h100">
        <Annotations />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Annotations from "@/components/SidePanels/Right/Annotations";

export default {
  data() {
    return {};
  },
  components: {
    Annotations
  },
  computed: {},
  watch: {},
  methods: {}
};
</script>

<style scoped>
.v-navigation-drawer {
  overflow: hidden !important;
}
.h100 {
  height: 100%;
}
.h60 {
  height: 60%;
}
.h40 {
  height: 40%;
}
.center-text {
  text-align: center;
}
.scrollable {
  overflow-y: auto;
}
</style>
