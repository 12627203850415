<template>
  <div class="h100">
    <v-card elevation="10" class="mx-auto mt5 h100">
      <v-card-text> </v-card-text>
    </v-card>
  </div>
</template>

<script>
// TODO MAP STATE FROM STORE/ANNOTATIONS THE ACTIVE ONE

export default {
  data() {
    return {};
  },
  components: {},
  computed: {},
  watch: {},
  methods: {}
};
</script>

<style scoped>
.h100 {
  height: 100%;
}
.mt5 {
  margin-top: 5px;
}
</style>
