var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[(_vm.currentPage == 'annotator')?[_c('v-menu',{attrs:{"open-on-hover":"","offset-y":"","max-height":"50%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"toolsbarbutton",attrs:{"dark":"","color":_vm.menuColor('annotation')}},Object.assign({}, tooltip, menu)),[_c('v-icon',{staticClass:"pr-2"},[_vm._v("mdi-draw")]),_vm._v(" "+_vm._s(_vm.selectedTool && _vm.selectedTool.type == "annotation" ? _vm.selectedTool.name : "Annotation")+" ")],1)]}}],null,true)},[_c('span',[_vm._v("Description")])])]}}],null,false,2103320439)},[_c('v-list',_vm._l((_vm.annotationTools),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.setSelectedTool(item)}}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-list-item-title',_vm._g({},Object.assign({}, tooltip)),[_vm._v(_vm._s(item.name))]),_c('v-list-item-subtitle',{staticClass:"font-italic font-weight-light pa-1",attrs:{"align":"right"}},[_vm._v(_vm._s(item.shortcut))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.description))])])],1)}),1)],1)]:_vm._e(),_c('v-menu',{attrs:{"open-on-hover":"","offset-y":"","max-height":"50%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"toolsbarbutton",attrs:{"color":_vm.menuColor('segmentation')}},Object.assign({}, tooltip, menu)),[_c('v-icon',{staticClass:"pr-2"},[_vm._v("mdi-brush")]),_vm._v(" "+_vm._s(_vm.selectedTool && _vm.selectedTool.type == "segmentation" ? _vm.selectedTool.name : "Segmentation")+" ")],1)]}}],null,true)},[_c('span',[_vm._v("Description")])])]}}])},[_c('v-list',_vm._l((_vm.segmentationTools),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.setSelectedTool(item)}}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-list-item-title',_vm._g({},Object.assign({}, tooltip)),[_vm._v(_vm._s(item.name))]),_c('v-list-item-subtitle',{staticClass:"font-italic font-weight-light pa-1",attrs:{"align":"right"}},[_vm._v(_vm._s(item.shortcut))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.description))])])],1)}),1)],1),_c('v-menu',{attrs:{"open-on-hover":"","offset-y":"","max-height":"50%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"toolsbarbutton",attrs:{"color":_vm.menuColor('utils')}},Object.assign({}, tooltip, menu)),[_c('v-icon',{staticClass:"pr-2"},[_vm._v("mdi-magnify")]),_vm._v(" "+_vm._s(_vm.selectedTool && _vm.selectedTool.type == "utils" ? _vm.selectedTool.name : "Utils")+" ")],1)]}}],null,true)},[_c('span',[_vm._v("Description")])])]}}])},[_c('v-list',_vm._l((_vm.utilsTools),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.setSelectedTool(item)}}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-list-item-title',_vm._g({},Object.assign({}, tooltip)),[_vm._v(_vm._s(item.name))]),_c('v-list-item-subtitle',{staticClass:"font-italic font-weight-light pa-1",attrs:{"align":"right"}},[_vm._v(_vm._s(item.shortcut))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.description))])])],1)}),1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }