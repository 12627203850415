<template>
  <div class="h100-header scrollbar">
    <v-treeview
      class="mt10"
      :items="items"
      :open="open"
      v-model="tree"
      @update:active="onUpdate"
      dense
      hoverable
      rounded
      item-key="id"
      activatable
    >
      <template v-slot:prepend="{ item, open }">
        <v-icon v-if="!item.type" color="#bed730">
          {{ open ? "mdi-folder-open" : "mdi-folder" }}
        </v-icon>
        <v-icon v-else>
          {{ types[item.type] }}
        </v-icon>
      </template></v-treeview
    >
  </div>
</template>

<script>
// {
//   id: id,
//   name: name,
//   file: "png"
//   children: []
// }

import { mapState } from "vuex";
import { getNode } from "@/common/api.treeview";
import {
  storeAnnotationData,
  storeImageMetadata
} from "@/common/api.annotations";
import { renderFileImage, fileManager, addDefaultTools } from "larvitar";

export default {
  data() {
    return {
      open: ["public"],
      types: {
        "image/png": "mdi-file-image",
        "image/jpg": "mdi-file-image"
      },
      tree: []
    };
  },
  computed: {
    ...mapState({
      items(state) {
        return state.treeview.tree;
      }
    }),
    activeItem: {
      get() {
        return this.$store.state.treeview.activeItem;
      },
      set(value) {
        this.$store.commit("treeview/setActiveItem", value);
      }
    }
  },
  watch: {
    activeItem: function(itemId) {
      let self = this;
      let item = getNode(itemId);
      if (item && item.file) {
        renderFileImage(item.file, "viewer", function() {
          let imageId = fileManager[item.file.webkitRelativePath];
          addDefaultTools("viewer");

          //csToolsUpdateImageIndex("viewer", imageId);
          self.$store.dispatch("annotations/setActiveItem", imageId);
          storeAnnotationData(imageId);
          setTimeout(function() {
            storeImageMetadata(item.file);
          }, 100);
        });
      }
    }
  },
  methods: {
    onUpdate(item) {
      let activeItem = item.length > 0 ? item[0] : null;
      this.$store.dispatch("treeview/setActiveItem", activeItem);
    }
  }
};
</script>

<style scoped>
.h100-header {
  height: calc(100% - 48px);
}
.scrollbar {
  overflow-y: auto;
}
.mt10 {
  margin-top: 10px;
}
.center-text {
  text-align: center;
}
</style>
